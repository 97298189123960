(function () {
    'use strict';
    $(document).ready(function () {

        $(".play-video").modalVideo({
            channel: 'vimeo'
        });


        var swiperCTA = new Swiper('.cta-slider .swiper-container', {
            navigation: {
                prevEl: '.cta-slider .swiper-button-prev',
                nextEl: '.cta-slider .swiper-button-next',
            },
        });

        var swiperSummary = new Swiper('.summary-slider .swiper-container', {
            navigation: {
                prevEl: '.summary-slider .swiper-button-prev',
                nextEl: '.summary-slider .swiper-button-next',
            },
            pagination: {
                el: '.summary-slider .swiper-pagination',
                clickable: true
            },
        });

        var swiperStablePosition = new Swiper('.stable-position .swiper-container', {
            centeredSlides: true,
            observer: true,
            observeParents: true,
            slidesPerView: "auto",
            navigation: {
                prevEl: '.stable-position .swiper-button-prev',
                nextEl: '.stable-position .swiper-button-next',
            },
            breakpoints: {
                768: {
                    centeredSlides: false
                }
            }
        });

        var swiperInnovations = new Swiper('.innovations .swiper-container', {
            centeredSlides: true,
            observer: true,
            observeParents: true,
            slidesPerView: "auto",
            navigation: {
                prevEl: '.innovations .swiper-button-prev',
                nextEl: '.innovations .swiper-button-next',
            },
            breakpoints: {
                768: {
                    centeredSlides: false
                }
            }
        });

    });
}(jQuery));
