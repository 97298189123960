(function () {
    'use strict';
    $(document).ready(function () {
        const $header = $('header.header');

        // SOCIAL SHARING
        const $share = $('#share');
        $share.css('opacity', 1);
        $share.jsSocials({
            showLabel: false,
            showCount: false,
            shareIn: "popup",
            text: document.title,
            url: window.location.href,
            shares: [
                {
                    share: "facebook",
                    logo: `${$share.attr('data-templateURL')}/dist/images/facebook.svg`,
                    inlineSvg: true,
                },
                {
                    share: "twitter",
                    logo: `${$share.attr('data-templateURL')}/dist/images/twitter.svg`,
                    inlineSvg: true,
                    via: "pgnig",
                },
                {
                    share: "linkedin",
                    logo: `${$share.attr('data-templateURL')}/dist/images/linkedin.svg`,
                    inlineSvg: true,
                }
            ]
        });

        // accordion scroll animation
        $('.subpage .accordion.scroll-animation .collapse, .subpage .accordion .collapse').on('shown.bs.collapse', function () {
            updateTableSize();
            const positionTop = $(this).parent().offset().top - $header.height() - 60;
            $('html, body').animate({
                scrollTop: positionTop
            }, 500);
            $(window).resize();
        });

        // accordion add active class
        $('.subpage .accordion .collapse').on('show.bs.collapse hide.bs.collapse', function () {
            $(this).parents('.accordion-card').toggleClass('active');
        })

        // responsive image enlarging
        $('.popup-trigger').click(function (e) {
            $(this).parent().find('.popup-image-container').fadeIn();
            e.preventDefault();
            return false;
        });

        $('.popup-image-container').click(function () {
            $(this).fadeOut();
        });

        //Tooltipster

        // default tooltips
        $('.default-tooltipster').tooltipster({
            maxWidth: 290,
            contentAsHTML: true,
            interactive: true,
            functionPosition: function (instance, helper, position) {
                position.coord.left = helper.geo.origin.offset.left - 20;
                return position;
            },
        });

        function updateTableSize() {
            $('.table-container').each(function () {
                if ($(this).parent().hasClass('panel-body')) return;
                var containerWidth = $(this).width();
                var tableWidth = $('table', this).width();

                if (tableWidth > containerWidth) {
                    $(this).addClass('scrollable');
                    $(this).mCustomScrollbar({
                        axis: "x",
                        mouseWheel: {
                            enable: true
                        }
                    });
                } else {
                    $(this).removeClass('scrollable');
                    $(this).mCustomScrollbar('destroy');
                }
            });
        }

        // Swipers
        $('.subpage .numbers-swiper').each(function () {
            const $nav = $(this).siblings('.swiper-navigation');
            new Swiper($(this)[0], {
                slidesPerView: 'auto',
                navigation: {
                    prevEl: $nav[0].querySelector('.swiper-navigation-prev'),
                    nextEl: $nav[0].querySelector('.swiper-navigation-next'),
                }
            })
        })

        $('.subpage .photo-card-icons-slider').each(function () {
            if ($(this).find('.swiper-slide').length > 1)
                new Swiper($(this)[0], {
                    effect: 'fade',
                    fadeEffect: {
                        crossFade: true
                    },
                    autoHeight: true,
                    navigation: {
                        prevEl: $(this).find('.swiper-navigation-prev'),
                        nextEl: $(this).find('.swiper-navigation-next'),
                    },
                    preventClicks: false,
                    preventClicksPropagation: false,
                })
        })

        $('.subpage .quotation-swiper').each(function () {
            if ($(this).find('.swiper-slide').length > 1)
                new Swiper($(this)[0], {
                    autoHeight: true,
                    navigation: {
                        prevEl: $(this).find('.swiper-navigation-prev'),
                        nextEl: $(this).find('.swiper-navigation-next'),
                    },
                    preventClicks: false,
                    preventClicksPropagation: false,
                })
        })

        $('.subpage .photo-numbers-text-swiper').each(function () {
            if ($(this).find('.swiper-slide').length > 1)
                new Swiper($(this)[0], {
                    effect: 'fade',
                    fadeEffect: {
                        crossFade: true
                    },
                    autoHeight: true,
                    navigation: {
                        prevEl: $(this).find('.swiper-navigation-prev'),
                        nextEl: $(this).find('.swiper-navigation-next'),
                    },
                    preventClicks: false,
                    preventClicksPropagation: false,
                })
        })

    });
}(jQuery));
