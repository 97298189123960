(function () {
    'use strict';
    $(document).ready(function () {
        const $header = $('header');

        // Menu toggle
        const menuBreakpoint = 768;
        let $wnd = window.innerWidth;
        $(".menu-toggle").on('click', function () {
            $('.menu-overlay').fadeToggle(300);
            $(this).toggleClass("open");
            $header.toggleClass('open');
        });

        new SimpleBar($header.find('.menu-overlay .scroll')[0], {
            autoHide: false,
        })

        const clearSlideStyles = ($el) => $el.css({
            display: '',
            height: '',
            paddingLeft: '',
            paddingRight: '',
            paddingTop: '',
            paddingBottom: '',
            marginTop: '',
            marginBottom: ''
        })

        $('ul.header-main-menu li.menu-item-has-children > a').on('click', function (e) {
            if ($(this).attr('href') === '#') e.preventDefault();

            const depth = +$(this).attr('data-depth');
            const $this = $(this);
            if ($this.hasClass('active')) {
                if (depth < 2 || $wnd < menuBreakpoint) {
                    $this.siblings('.submenu').stop().slideUp(300, function () {
                       clearSlideStyles($(this));
                    });
                    $this.parent().find('.submenu.open').stop().slideUp(300, function () {
                       clearSlideStyles($(this));
                    });
                }
                $this.parent().find('.submenu.open').removeClass('open');
                $this.siblings('.submenu').removeClass('open');
                $this.parent().removeClass('open');
                $this.removeClass('active');
            } else {
                if (depth < 2 || $wnd < menuBreakpoint) {
                    $this.parent().parent().parent().find('li.open>ul.submenu').slideUp(300, function () {
                        clearSlideStyles($(this));
                    });
                }
                $this.parent().parent().parent().find('li.open>ul.submenu.open').removeClass('open');
                $this.parent().parent().parent().find('li.open>a.active').removeClass('active');
                $this.parent().parent().parent().find('li.open').removeClass('open');

                if (depth < 2 || $wnd < menuBreakpoint) {
                    $this.siblings('.submenu').stop().slideToggle(300);
                }
                $this.siblings('.submenu').toggleClass('open');
                $this.parent().toggleClass('open');
                $this.toggleClass('active');
            }

        });

        $(window).resize(function () {
            $wnd = window.innerWidth;
            $('.header-right nav.open').fadeOut(300, function () {
                $(this).css('display', '');
                $(this).removeClass('open');
            });
            $header.removeClass('open');
            $('.menu-toggle').removeClass('open');
            $('.menu-overlay').fadeOut();

            // Close submenus
            $('ul.header-main-menu li.menu-item-has-children > a.active').each(function () {
                const depth = +$(this).attr('data-depth');
                if (depth < 2) {
                    $(this).siblings('.submenu').stop().slideUp(300, function () {
                        clearSlideStyles($(this));
                    });
                }
                $(this).siblings('.submenu.open').removeClass('open');
                $(this).removeClass('active');
                $(this).parent().removeClass('open');
            });
        });

    });
}(jQuery));
