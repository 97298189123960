(function () {
    'use strict';
    $(document).ready(function () {
        const $header = $('header.header');

        // Smooth scroll to
        $('.scroll-animation').on('click', function (e) {
            e.preventDefault();

            const href = $(this).attr('href');
            if (href) {
                $("html, body").animate({
                    scrollTop: $(href).offset().top - $header.height() - 60
                }, 1000);
            }
        });

        $('.scroll-animation a').on('click', function (e) {
            e.stopPropagation();
        });

        // GSAP Elements Animations

        if($(".gsap-from-top").length) {
            gsap.set(".gsap-from-top", {
                opacity: 0,
                y: -100
            });
            ScrollTrigger.batch(".gsap-from-top", {
                onEnter: batch => gsap.to(batch, {
                    opacity: 1,
                    y: 0,
                    delay: .25,
                    duration: .5,
                    stagger: .25
                }),
            });
        }

        if($(".gsap-from-bottom").length) {
            gsap.set(".gsap-from-bottom", {
                opacity: 0,
                y: 100
            });
            ScrollTrigger.batch(".gsap-from-bottom", {
                onEnter: batch => gsap.to(batch, {
                    opacity: 1,
                    y: 0,
                    delay: .25,
                    duration: .5,
                    stagger: .25
                }),
            });
        }

        if($(".gsap-from-left").length) {
            gsap.set(".gsap-from-left", {
                opacity: 0,
                x: -100
            });
            ScrollTrigger.batch(".gsap-from-left", {
                onEnter: batch => {
                    var duration = .5;
                    if ($(batch).hasClass('gsap-slow')) {
                        duration = 3;
                    }
                    gsap.to(batch, {
                        opacity: 1,
                        x: 0,
                        delay: .25,
                        duration: duration,
                        stagger: .25,
                        ease: 'power2.easeOut'
                    })
                },
            });
        }

        if($(".gsap-from-right").length) {
            gsap.set(".gsap-from-right", {
                opacity: 0,
                x: 100
            });
            ScrollTrigger.batch(".gsap-from-right", {
                onEnter: batch => {
                    var duration = .5;
                    if ($(batch).hasClass('gsap-slow')) {
                        duration = 3;
                    }
                    gsap.to(batch, {
                        opacity: 1,
                        x: 0,
                        delay: .25,
                        duration: duration,
                        stagger: .25,
                        ease: 'power2.easeOut'
                    })
                },
            });
        }

        if($(".gsap-fade-in").length) {
            gsap.set(".gsap-fade-in", {
                opacity: 0
            });
            ScrollTrigger.batch(".gsap-fade-in", {
                onEnter: batch => gsap.to(batch, {
                    opacity: 1,
                    delay: .25,
                    duration: .5,
                    stagger: .25
                }),
            });
        }

        //Search trigger
        $('.search-trigger').click(function () {
            $(this).toggleClass('active');
            $('.search-panel').fadeToggle('active');
            return false;
        });

        $(window).on('scroll', () => {
            if ($(window).scrollTop() > 0) $header.addClass('scrolled')
            else $header.removeClass('scrolled');
        })

        //Footer dropdown
        $("#show-previous-reports").click(function(a) {
            $(this).toggleClass("active"),
                $(".previous-reports").slideToggle(),
                a.preventDefault();
        });

        objectFitImages();
    });
}(jQuery));
